<template>
  <div>
    <googlePlay :data="data" :isPlay="isPlay" :installing="installing" :schedule="schedule" @play="play" @az="az" />
    <pop :data="data" @childEvent="az" v-if="showPop" @closePop="closePop"></pop>
    <div id="loading-box" v-if="isLoding">
      <div class="mask"></div>
      <div class="bd">
        <img width="45" class="rotate" src="./assets/loading.svg">
        <p>Loading...</p>
      </div>
    </div>
    <popSpin :data="data" :popshow="popsShow.pop1" @closePops="closePops"></popSpin>
  </div>

</template>

<script setup>
import pop from '../src/components/pop.vue';
import { ref, reactive } from "vue";
import googlePlay from './components/googlePlay.vue'
import { getData } from "./api/index"
import popSpin from './components/popSpin.vue';
const installPromptEvent = ref(null);
const isPlay = ref(false);
const installing = ref(false);
const schedule = ref(0);
const interval = ref(null);
const showPop = ref(false);

let isLoding = ref(true);
let IntervalID = null;
let popsShow = reactive({
  pop1: false,
})
let data = reactive({
  data: {
    browser_icon: "",
    desc: "",
    desktop_icon: "",
    developer_name: "",
    image: [],
    install_count: 999999,
    install_icon: "",
    install_url: "",
    name: "",
    push_url: "",
    score: 5,
    score_count: 99999,
    sub_name: "",
    suit_age: 0,
  }
});

window.addEventListener('beforeinstallprompt', event => {
  console.log('installPromptEvent=>', event);
  event.preventDefault();
  installPromptEvent.value = event;
  isLoding.value = false;
  clearInterval(loadingIntervalID);
  loadingIntervalID = null
  setPlayValue(false)
  console.log('beforeinstallprompt');

});
const setPlayValue = (value) => {
  isPlay.value = value;
  localStorage.setItem('dw', value)
}
let loadingIntervalID = setInterval(() => {
  isLoding.value = false;
}, 5000);
showPopFun();
const closePops = (val) => {
  popsShow[val] = false;
}
const az = () => {
  console.log('installPromptEvent.value=>', installPromptEvent.value)
  if (installPromptEvent.value) {

    installPromptEvent.value.prompt();
    installPromptEvent.value.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('用户已接受安装提示');
        installing.value = true;
        interval.value = setInterval(() => {
          if (schedule.value < 100) {
            schedule.value += 5;
          }
        }, 100);
      } else {
        console.log('用户取消了安装');
      }
      // 清空事件对象，以便下次重新触发
      installPromptEvent.value = null;
    });

  } else {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      window.location.href = getGameUrl();
    } else {
      // window.open(window.location.href, '_blank');
      return;
    }
  }
}
function play() {
  gotoGame();
}
function closePop() {
  showPop.value = false;
  showPopFun();
}
function getAgms() {
  return window.location.search;
}
function getGameUrl() {
  var urlSearch = localStorage.getItem('urlSearch');
  return data.data.push_url + urlSearch;
}
function createIFrame() {
  var iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.top = '0';
  iframe.style.left = '0';
  iframe.style.width = '100%';
  iframe.style.height = '100%';

  iframe.src = data.data.push_url + getAgms().split('?')[1];
  document.body.appendChild(iframe);
  document.getElementById('all').style.display = 'none';
}
function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}
function gotoGame() {
  window.location.replace(getGameUrl());
}
function showPopFun() {
  IntervalID = setInterval(() => {
    if (showPop.value == false && isPlay.value == false) {
      showPop.value = true;
      clearInterval(IntervalID);
      IntervalID = null;
    }
  }, 5000);
}

const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  );
};
function isAndroidMobile() {
  var isMobile = /Android/i.test(navigator.userAgent);
  return isMobile;
}
if (isAndroidMobile() && !isChrome()) {
  // 如果是 Android手机端，并且不在 Chrome 浏览器中运行，则跳转到 Chrome 浏览器
  window.location.href =
    'intent://' +
    window.location.host +
    window.location.pathname +
    '#Intent;scheme=http;package=com.android.chrome;end';
}

const dwValue = localStorage.getItem('dw')
if (dwValue === 'true') {
  isPlay.value = true
}

window.addEventListener('appinstalled', (evt) => {
  installPromptEvent.value = null;
  setPlayValue(true)
  var url = window.location.href;
  var urlSearch = new URL(url).search.replace('?', '');
  //获取 URL 参数，不带?

  console.log('urlSearch:', urlSearch);
  localStorage.setItem('urlSearch', urlSearch);
  try {
    if (fbq) {
      fbq('trackCustom', 'appinstalled');
    }
  } catch (error) {
    console.error('fbq method failed:', error);
  }
});

window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
  if (evt.matches) {
    gotoGame();
  }
});

if (getPWADisplayMode() === 'standalone') {
  gotoGame();
}

const getDatas = async () => {
  // 发送请求获取数据
  if (localStorage.getItem('data')) {
    data.data = JSON.parse(localStorage.getItem('data'));
  } else {
    const res = await getData();
    if (res.data.data) {
      data.data = res.data.data;
      document.title = res.data.data.name;
      localStorage.setItem('data', JSON.stringify(res.data.data))
      document.querySelector('link[rel="icon"]').setAttribute('href', data.data.browser_icon);
    }
  }
};

getDatas();

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
