import axios from "./axios";

export const getData = (id) => {
    if (localStorage.getItem('id=')) {
        return axios.get('/api/app/details?id=' + localStorage.getItem('id='))
    } else {
        let url = window.location.href;
        let urlId = new URL(url).search.replace('?id=', '');
        if (urlId) {
            localStorage.setItem('id=', urlId);
        }

        return axios.get('/api/app/details?id=' + urlId)
    }

}



