import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
// import './registerServiceWorker'
import { v4 as uuidv4 } from 'uuid';
const pinia = createPinia()
window.isPlay = false;
createApp(App).use(pinia).mount('#app')

// 创建 link 元素
let url = window.location.href;
let urlId = new URL(url).search.replace('?id=', '');
const linkElement = document.createElement('link');
linkElement.rel = 'manifest';
linkElement.href = `https://oss.easyads.app/manifest/${urlId}.json`; // 替换为你的 manifest.json 文件的 URL

// 将 link 元素添加到文档头部
document.head.appendChild(linkElement);
if (localStorage.getItem('uuid')) {
  const uuid = localStorage.getItem('uuid');
} else {
  const uuid = uuidv4();
  localStorage.setItem('uuid', uuid);
}

