<template>
    <div v-if="popshow">
        <div id="popBox" class="pop-box">
            <div class="pop-top">
                <div class="template-head">
                    <div class="box">
                        <div class="l">
                            <a>
                                <svg class="kOqhQd" aria-hidden="true" viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0,0h40v40H0V0z"></path>
                                    <g>
                                        <path
                                            d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                            fill="#EA4335"></path>
                                        <path
                                            d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                            fill="#FBBC04"></path>
                                        <path
                                            d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                            fill="#4285F4"></path>
                                        <path
                                            d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                            fill="#34A853"></path>
                                    </g>
                                </svg>
                                <h1>Google Play</h1>
                            </a>
                        </div>
                        <div class="r">
                            <a>
                                <img width="24" src="../assets/search.svg" alt="">
                            </a>
                            <a> <img width="24" src="../assets/help.svg" alt=""></a>
                            <a> <img src="../assets/avatar.png" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="main-up">
                    <div class="logo">
                        <div class="box"></div>
                        <img src="https://static.w2.app/20240327/8d770d547ebe85b.png" alt="666">
                    </div>
                    <div class="info">
                        <h1>{{ data.data.name }}</h1>
                        <h2>{{ data.data.desc }}</h2>
                        <p>Verified by App</p>
                    </div>
                </div>
                <ul class="information-list">
                    <li>
                        <strong>
                            {{ data.data.score }}
                            <img src="../assets/start.png" alt="">
                        </strong>
                        <p><span>{{ data.data.score_count }}</span> reviews</p>
                    </li>
                    <li>
                        <strong><span>{{ data.data.install_count }}</span> +</strong>
                        <p>Downloads</p>
                    </li>
                    <li>
                        <strong><img class="large" src="../assets/7.svg"></strong>
                        <p>Rated for 7+</p>
                    </li>
                </ul>

                <div class="btn-box">
                    <button disabled="" class="btn">The app has been removed</button>
                </div>

            </div>
            <div id="popBd" class="pop-bd">
                <img class="closed-btn" @click="closePop" src="../assets/turntable3/close.png" alt="">
                <div class="turntable-container">
                    <img class="turntable-title" src="../assets/turntable3/title.png" alt="">
                    <div class="turntable-content">
                        <img class="turntable-bg" src="../assets/turntable3/background.png" alt="">
                        <img class="turntable-halo" src="../assets/turntable3/halo.png" alt="">
                        <img class="turntable-coin" src="../assets/turntable3/coin.png" alt="">
                        <div class="turntable-main">
                            <img :class="{turntable_bottom:true, runSpin:run}" id="turntableRotate"
                                src="../assets/turntable3/turntable.png" alt="" style="">
                            <img class="turntable-light" src="../assets/turntable3/light.png" alt="">
                            <img class="turntable-pointer" src="../assets/turntable3/pointer.png" alt="">
                            <button @click="handleClickSpin" class="turntable-button">
                                <img src="../assets/turntable3/button.png" alt="">
                                <span>1</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="app-items">
                    <strong>5555</strong>
                    <img src="../assets/tele.png" alt="">
                    <img src="../assets/twtter.png" alt="">
                    <img src="../assets/facebook.png" alt="">
                    <img src="../assets/wechat.png" alt="">
                </div>
                <p class="intro">123</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import "./index.css"
const props = defineProps({
    data: {
        type: Object
    },
    popshow:{
        type:Boolean
    }
})
const emit = defineEmits(['closePops'])
let run = ref(false);

const handleClickSpin = () => {
    console.log(run.value);
    if (!run.value) {
        run.value = true;
        // setTimeout(() => {
        //     run.value = false;
        // }, 3000);
    }

}

const closePop = ()=>{
    emit('closePops','pop1')
}
</script>

<style  scoped>
.runSpin{
    transform: rotate(990deg);
    animation: run_spin 3s forwards;
}
@keyframes light-rotate {
    0% {
        transform: rotate(990deg);
    }

    100% {
        transform: rotate(1410deg);
    }
}
</style>